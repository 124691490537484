import { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';

import { AppContext } from '../../app/context';
import { TTab, tabs } from '../../app/router/tabs';
import { Contributions, Stats } from '../../pages';

import Content from './content';
import DownloadDialog from './download-dialog';
import Header from './header';
import TabBar from './tab-bar';

function ContentLayout(): JSX.Element {
  const {
    routing: { contributionId },
    zone: { current: currentZone },
    actions: { setCurrentTab, setContributionId },
  } = useContext(AppContext);
  const [selectedTab, selectTab] = useState<TTab | null>();
  const [downloadDialogOpen, openDownloadDialog] = useState(false);
  const navigate = useNavigate();
  const { countryCodeOrTab, zoneCodeOrTabOrId, tabOrId, id } = useParams();

  useEffect(() => {
    getSelectedTab();
  }, [countryCodeOrTab, zoneCodeOrTabOrId, tabOrId, id]);

  useEffect(() => {
    let _contributionId: number | undefined;
    if (id !== undefined) _contributionId = parseInt(id, 10);
    else if (tabOrId !== undefined && !Number.isNaN(tabOrId))
      _contributionId = parseInt(tabOrId, 10);
    else if (zoneCodeOrTabOrId !== undefined && !Number.isNaN(zoneCodeOrTabOrId))
      _contributionId = parseInt(zoneCodeOrTabOrId, 10);

    if (!_contributionId || Number.isNaN(_contributionId)) setContributionId(null);
    else setContributionId(_contributionId);
  }, [zoneCodeOrTabOrId, tabOrId, id]);

  function getSelectedTab(): TTab | null {
    console.log(countryCodeOrTab, zoneCodeOrTabOrId, tabOrId);
    const tab =
      tabs.find(
        (key) => key === countryCodeOrTab || key === zoneCodeOrTabOrId || key === tabOrId,
      ) || null;

    selectTab(tab);
    if (tab) setCurrentTab(tab);
    else navigate('./stats');

    return tab;
  }

  if (!selectedTab) return <></>;

  return (
    <>
      <Wrapper>
        <Header openDownloadDialog={openDownloadDialog} />
        {contributionId === null && (
          <TabBar selectedTab={selectedTab} selectTab={() => undefined} />
        )}
        <Content>
          {selectedTab === 'stats' && <Stats />}
          {selectedTab === 'contributions' && <Contributions />}
        </Content>
      </Wrapper>
      <DownloadDialog
        cyclabilityZone={currentZone}
        onClose={() => openDownloadDialog(false)}
        open={downloadDialogOpen}
      />
    </>
  );
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
`;

export default ContentLayout;
